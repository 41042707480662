import React, { useState, useContext, useEffect } from 'react';
import {  BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import ProposalPage from './components/Proposals/ProposalPage';

const AppRoutes = () => {

  return (
    <Router>
                <Routes>
                  <Route path={'/proposals/:proposalId'} element={<ProposalPage />}/>
                  <Route
                  path="*"
                  element={
                    <main style={{ padding: "1rem" }}>
                      <p>There's nothing here!</p>
                    </main>
                  }
                  />
                </Routes>
            </Router>
  )
}

const Styles = {
    appModalContainer: {
      display: 'grid',
      justifyItems: 'center',
      alignItems: 'center'
    }
  }

export default AppRoutes