import logo from './logo.svg';
import AppRoutes from './AppRoutes';
import './App.css';

function App() {
  return (
    <AppRoutes/>
  );
}

export default App;
